<template>
  <div class="contentSuiviRegieRecap">
    <div class="entete">
      <div class="box-all-filter">
        <div class="box-label-champ mr-2">
          <div class="label-box-style">
            <span class="title-tabel">Année</span>
          </div>
          <b-form-select
            class="b-form-select-new-style  bg-select"
            v-model="filteryear"
            :options="ListYear"
            @change="handleChangeYear"
          ></b-form-select>
        </div>

        <div class="box-label-champ mr-2">
          <b-form-checkbox
            v-show="switchMonth == false"
            v-model="switchMonth"
            @change="handleChangeSwitch('switchWeek')"
            name="check-button"
            switch
            class="check-th"
            :value="true"
            :unchecked-value="false"
          >
            <b class="mt-1 switch-label-style">Par Mois </b>
          </b-form-checkbox>
          <b-form-checkbox
            v-show="switchWeek == false"
            v-model="switchWeek"
            @change="handleChangeSwitch('switchMonth')"
            name="check-button"
            switch
            class="check-th"
            :value="true"
            :unchecked-value="false"
          >
            <b class="mt-1 switch-label-style">Par Semaine </b>
          </b-form-checkbox>
        </div>
        <div class="box-label-champ mr-2" v-if="switchMonth == true">
          <div class="label-box-style  w-46-px">
            <span class="title-tabel">Mois</span>
          </div>
          <b-form-select
            class="b-form-select-new-style bg-select  w-63-px"
            v-model="filterMonth"
            :options="getListMonth"
            @change="handleChangeMonth"
          ></b-form-select>
        </div>

        <div class="box-label-champ mr-2" v-if="switchWeek == true">
          <div class="label-box-style">
            <span class="title-tabel">Semaine</span>
          </div>

          <b-form-select
            class="b-form-select-new-style  bg-select"
            v-model="filterSemaine"
            :options="ComputedListWeek"
            @change="handleChangeSemaine"
          ></b-form-select>
        </div>

        <div class="box-label-champ mr-2">
          <div class="label-box-style">
            <span class="title-tabel">Dépot</span>
          </div>

          <SelectComponent
            :options="computedgetAllDepotRecapRegie"
            :value="filteDepot"
            label="name"
            champName="dépot"
            filterName="filteDepot"
            :change="handleChange"
            :track-by="'name'"
            placeholder="Rechercher"
            :searchable="true"
            :showLabels="false"
            classToUse="select-vue-component-style w-1  bg-select-vue"
          />
        </div>
        <div v-if="getLoadingRecapRegie" class="chargement">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="box-end-position">
          <b-button
            v-if="cantExport(this.$options.name)"
            size="sm"
            class="button-export-style  ml-2"
            @click="exportFiles"
            >Export</b-button
          >
        </div>
      </div>
    </div>
    <div class="body-box-rapport">
      <div class="table-rapport-style w-100-p">
        <b-table-simple
          id="my-table"
          class="custom-table-style tableRecapRegie"
          bordered
          sticky-header
          hover
          responsive
          head-variant="light"
          v-if="computedGetSuiviRegie"
        >
          <b-thead>
            <b-tr>
              <b-th class="th-rouge-bg-color th-border-raduis-left color-white"
                >DÉPÔT stockage</b-th
              >
              <b-th class="th-rouge-bg-color color-white">Régie</b-th>
              <b-th class="th-rouge-bg-color color-white"
                >Somme de Total Surface à isoler</b-th
              >
              <b-th
                class="th-rouge-bg-color  th-border-raduis-rigth color-white"
                >Somme de CA Total</b-th
              >
            </b-tr>
          </b-thead>
          <b-tbody>
            <template v-for="(item, index) in computedGetSuiviRegie"
              ><b-tr :key="index">
                <b-th
                  :rowspan="computedRegieSize(item.value.workers) + 1"
                  class="text-align-left th-peige-bg-color font-size-11 "
                  >{{ item.depot | formatageLabel }}</b-th
                > </b-tr
              ><template v-for="(regie, name) in item.value.workers">
                <b-tr :key="'regie' + name + index">
                  <b-td class="text-align-left th-green-bg-color ">{{
                    name | formatageLabel
                  }}</b-td>
                  <b-td class="text-align-right th-green-bg-color">{{
                    regie.m2
                  }}</b-td>
                  <b-td class="text-align-right th-green-bg-color"
                    >{{ regie.CA }} €
                  </b-td>
                </b-tr>
              </template>
              <b-tr :key="'depot' + index">
                <b-th
                  colspan="2"
                  class="text-align-left th-jaune-bg-color font-size-11 "
                  >Total {{ item.depot | formatageLabel }}</b-th
                >
                <b-td class="text-align-right th-jaune-bg-color f-w-800 ">{{
                  item.value.totalm2
                }}</b-td>
                <b-td class="text-align-right th-jaune-bg-color f-w-800 "
                  >{{ item.value.total }} €
                </b-td>
              </b-tr>
            </template>
            <b-tr v-if="computedGetSuiviRegie.length > 0" class="tr-total">
              <b-th colspan="2" class="text-align-left ">
                Total général
              </b-th>
              <b-td class=" f-w-800 text-align-right">{{
                computedTotalGeneral.totalm2
              }}</b-td>
              <b-td class=" f-w-800 text-align-right"
                >{{ computedTotalGeneral.total }} €</b-td
              >
            </b-tr>

            <b-tr v-if="computedGetSuiviRegie.length == 0">
              <b-td colspan="4">
                Il n'y a aucun enregistrement à afficher
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
export default {
  name: 'Récapitulatif-régie-par-zone',
  data() {
    return {
      switchMonth: false,
      switchWeek: true,
      filterMonth: 1,
      filteDepot: { name: 'tous', id: null },
      filteryear: null,
      filterSemaine: null,
      ListYear: [],
      ListWeek: []
    };
  },
  methods: {
    ...mapActions([
      'getRecapRegieFiltred',
      'fetchAllDepotRecap',
      'exportExcelRecapRegie',
      'resetRecapRegie'
    ]),
    rowClass(item, type) {
      return 'ligneNormale';
    },
    async handleChange(payload) {
      if (payload) {
        this[payload.champName] = payload.value;
      }
      const response = await this.fetchAllDepotRecap({
        semaine: this.filterSemaine,
        year: this.filteryear,
        month: this.filterMonth,
        switchMonth: this.switchMonth
      });
      if (response) {
        if (this.getAllDepotRecapRegie.length > 0) {
          await this.FilterRecapRegie();
        } else {
          this.resetRecapRegie();
        }
      }
    },
    async handleChangeSemaine(payload) {
      if (payload) {
        this[payload.champName] = payload.value;
      }
      const response = await this.fetchAllDepotRecap({
        semaine: this.filterSemaine,
        year: this.filteryear,
        month: this.filterMonth,
        switchMonth: this.switchMonth
      });
      if (response) {
        this.filteDepot = { name: 'tous', id: null };

        if (this.getAllDepotRecapRegie.length > 0) {
          await this.FilterRecapRegie();
        } else {
          this.resetRecapRegie();
        }
      }
    },
    async handleChangeYear() {
      var totalWeek = moment({ year: this.filteryear }).isoWeeksInYear();
      this.ListWeek = [];
      for (var week = 1; week <= totalWeek; week++) {
        this.ListWeek.push({ value: 's' + week, text: week });
      }
      const response = await this.fetchAllDepotRecap({
        semaine: this.filterSemaine,
        year: this.filteryear,
        month: this.filterMonth,
        switchMonth: this.switchMonth
      });
      if (response) {
        this.filteDepot = { name: 'tous', id: null };
        if (this.getAllDepotRecapRegie.length > 0) {
          await this.FilterRecapRegie();
        } else {
          this.resetRecapRegie();
        }
      }
    },
    async handleChangeMonth() {
      const response = await this.fetchAllDepotRecap({
        semaine: this.filterSemaine,
        year: this.filteryear,
        month: this.filterMonth,
        switchMonth: this.switchMonth
      });

      if (response) {
        this.filteDepot = { name: 'tous', id: null };
        if (this.getAllDepotRecapRegie.length > 0) {
          await this.FilterRecapRegie();
        } else {
          this.resetRecapRegie();
        }
      }
    },
    FilterRecapRegie() {
      this.getRecapRegieFiltred({
        semaine: this.filterSemaine,
        year: this.filteryear,
        month: this.filterMonth,
        switchMonth: this.switchMonth,
        depot: this.filteDepot,
        type: 'regie'
      });
    },
    async exportFiles() {
      await this.exportExcelRecapRegie({
        semaine: this.filterSemaine,
        year: this.filteryear,
        month: this.filterMonth,
        switchMonth: this.switchMonth,
        depot: this.filteDepot,
        type: 'regie'
      });
    },
    async handleChangeSwitch(item) {
      if (item == 'switchMonth') {
        this.switchMonth = false;
      } else {
        this.switchWeek = false;
      }
      const response = await this.fetchAllDepotRecap({
        semaine: this.filterSemaine,
        year: this.filteryear,
        month: this.filterMonth,
        switchMonth: this.switchMonth
      });
      if (response) {
        this.filteDepot = { name: 'tous', id: null };
        if (this.getAllDepotRecapRegie.length > 0) {
          await this.FilterRecapRegie();
        } else {
          this.resetRecapRegie();
        }
      }
    }
  },

  computed: {
    ...mapGetters([
      'getLoadingRecapRegie',
      'getRecapRegie',
      'getAllDepotRecapRegie',
      'cantExport',
      'getListMonth'
    ]),
    ComputedListWeek() {
      // return [{ value: null, text: 'tous' }, ...this.ListWeek];
      return this.ListWeek;
    },
    computedGetSuiviRegie() {
      return this.getRecapRegie;
    },
    computedgetAllDepotRecapRegie() {
      const all_depot = this.getAllDepotRecapRegie.map(item => {
        return { id: item, name: item };
      });
      return [{ id: null, name: 'tous' }, ...all_depot];
    },
    computedRegieSize() {
      return function(data) {
        var size = 0,
          key;
        for (key in data) {
          if (data.hasOwnProperty(key)) size++;
        }
        return size;
      };
    },
    computedTotalGeneral() {
      var total = 0;
      var totalm2 = 0;
      for (let i = 0; i < this.getRecapRegie.length; i++) {
        total = total + parseFloat(this.getRecapRegie[i].value.total);
        totalm2 = totalm2 + parseFloat(this.getRecapRegie[i].value.totalm2);
      }

      return { total: total, totalm2: totalm2 };
    }
  },
  components: {
    SelectComponent: () => import('../SelectComponent')
  },
  filters: {
    formatageLabel: value => {
      return value.split('_').join(' ');
    }
  },
  async mounted() {
    var courantYear = moment().year();
    this.filteryear = courantYear;
    this.filterSemaine = 's' + moment().isoWeek();
    for (var i = 2019; i <= courantYear; i++) {
      this.ListYear.push({ value: i, text: i });
    }
    var totalWeek = moment({ year: this.filteryear }).isoWeeksInYear();
    for (var week = 1; week <= totalWeek; week++) {
      this.ListWeek.push({ value: 's' + week, text: week });
    }
    const response = await this.fetchAllDepotRecap({
      semaine: this.filterSemaine,
      year: this.filteryear,
      month: this.filterMonth,
      switchMonth: this.switchMonth
    });
    if (response) {
      if (this.getAllDepotRecapRegie.length > 0) {
        await this.FilterRecapRegie();
      } else {
        this.resetRecapRegie();
      }
    }
  }
};
</script>

<style scoped lang="scss">
.contentSuiviRegieRecap {
  padding: 10px !important;
  margin: 0px !important;
  height: calc(100vh - 110px);
  display: block;
  .entete {
    width: 100%;
    .chargement {
      font-size: 8px;
      margin-left: 5px;
      .spinner-border {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
  .body-box-rapport {
    height: calc(100vh - 175px);
  }
}
.tableRecapRegie {
  max-height: calc(100vh - 190px) !important;
  height: calc(100vh - 191px) !important;
  margin-bottom: 0px;
  td {
    padding: 5px;
  }
}
</style>
<style lang="scss">
.tableRecapRegie {
  .table {
    display: table;
  }
  td,
  th {
    white-space: nowrap !important;
    width: auto !important;
    min-width: auto;
    padding: 3px 13px !important;
    color: #212529;
  }
  th {
    font-size: 13px !important;
    min-width: 180px;
  }
  td {
    font-size: 10px;
  }
  .text-align-left {
    text-align: left;
  }
  .text-align-right {
    text-align: right;
  }
  .th-rouge-bg-color {
    // background-color: #e9999c !important;
    background-color: #adacca !important;
  }
  .th-jaune-bg-color {
    background-color: #bebcee !important;
  }
  .th-peige-bg-color {
    background-color: #dfdef4 !important;
    // #dcdafe !important
  }
  .th-white-bg-color {
    color: white !important;
    padding: 10px 10px !important;
  }
  .th-green-bg-color {
    background-color: #f0f1ff !important;
  }
  .th-border-raduis-left {
    border-radius: 10px 0px 0px 0px;
  }
  .th-border-raduis-rigth {
    border-radius: 0px 10px 0px 0px;
  }
  .font-size-11 {
    font-size: 11px !important;
  }
  .f-w-800 {
    font-weight: 800;
  }
  .color-white {
    color: white !important;
  }
  .tr-total {
    background-color: #f2f2f2e8;
    font-weight: 800;
    td {
      font-size: 13px;
    }
  }
}
</style>
